.faq {
  @include adaptiveDesktopToMobile('padding-top', 200, 120);
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
}
.faq__title {
  margin-bottom: 30px;
}
.faq__cards {
}
.faq__card {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.10);
  @include adaptiveDesktopToMobile('padding-top', 20, 10);
  @include adaptiveDesktopToMobile('padding-bottom', 20, 10);
  @include adaptiveDesktopToMobile('padding-left', 40, 10);
  @include adaptiveDesktopToMobile('padding-right', 40, 10);

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.faq__card-top {
  background: unset;
  border: unset;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;

  @include vp-767 {
    .is-active & {
      align-items: flex-start
    }
  }
}
.faq__card-title {
  @include adaptiveDesktopToMobile('font-size', 28, 20);
  font-style: normal;
  font-weight: 500;
  max-width: 800px;
  margin: 0;
  margin-right: 15px;
}
.faq__card-arrow {
  flex-shrink: 0;
  transition: $trans-default;
  width: 30px;
  height: 30px;

  .is-active & {
    transform: rotate(180deg);
  }
}
.faq__card-content {
  max-width: 1125px;

  &::before {
    content: '';
    display: block;
    height: 10px;
  }

  a {
    color: #07F;
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}