.scheme {
  @include adaptiveDesktopToMobile('padding-top', 200, 120);
}
.scheme__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include adaptiveDesktopToMobile('gap', 100, 20);
  @include adaptiveDesktopToMobile('margin-bottom', 50, 30);

  @include vp-1279 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.scheme__title {
  
}
.scheme__description {
  max-width: 470px;
  @include adaptiveDesktopToMobile('font-size', 25, 18);
}
.scheme__main {
  @include vp-1279 {
    @include adaptiveDesktopToMobile('padding-left', 216, 19);
    @include adaptiveDesktopToMobile('padding-right', 216, 19);
    @include adaptiveDesktopToMobile('margin-left', -216, -19);
    @include adaptiveDesktopToMobile('margin-right', -216, -19);
    padding-bottom: 40px;
    overflow-x: scroll;

    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
      width: 24px; /* ширина для вертикального скролла */
      height: 16px; /* высота для горизонтального скролла */
      background-color: #FFF;
      border-radius: 20px;


    }

    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: var(--black, #0A1729);
    }
  }
}
.scheme__main-svg {
  opacity: 0.2;
  width: 100%;
  @include adaptiveDesktopToMobile('height', 733, 500);

  @include vp-1279 {
    pointer-events: none;
    opacity: 1;
    width: auto;
  }
}
.scheme__variant {
  display: none;
  width: 100%;
  @include adaptiveDesktopToMobile('height', 733, 500);
}