html, body {
  scroll-behavior: smooth;
}

.title {
  &-h2 {
    @include adaptiveDesktopToMobile('font-size', 70, 35);
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }
  &-h3 {
    @include adaptiveDesktopToMobile('font-size', 45, 25);
    font-weight: 500;
    margin: 0;
  }
}

.text {
  &-p {
    @include adaptiveDesktopToMobile('font-size', 25, 18);
  }
}

.bg-wrapper {
  position: relative;
  overflow: hidden;
}

.main-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  // transform: translateY(-8%);
  z-index: -1;
  max-width: unset;

  &.mobile {
    display: none;
  }

  @include vp-1279 {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
}