.feedback {
  @include adaptiveDesktopToMobile('padding-top', 200, 120);
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
  border: 1px solid var(--border-gradient, #0A1729);
  background: linear-gradient(98deg, rgba(255, 255, 255, 0.08) 6.53%, rgba(255, 255, 255, 0.02) 92.43%);
}
.feedback__title {
  margin-bottom: 40px;
}
.feedback__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include vp-1279 {
    grid-template-columns: auto;
    gap: 10px;
  }
}
.feedback__input {
  border-radius: 40px;
  background: var(--White, #FFF);
  padding: 20px 30px;
  color: var(--black, rgba(10, 23, 41, 0.8));
  outline: none;
}
.feedback__submit {
}