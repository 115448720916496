.welcome {
  
}
.welcome__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  &.mobile {
    display: none;
  }

  @include vp-1279 {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
}
.welcome__wrapper {
  display: grid;
  @include adaptiveDesktopToMobile('--padding-left', 500, 0);
  grid-template-columns: var(--padding-left) auto;

  &::before {
    content: '';
  }

  @include vp-1279 {
    grid-template-columns: auto;
    @include adaptiveDesktopToMobile('padding-top', 1000, 200);
    row-gap: 40px;

    &::before {
      display: none
    }
  }
}
.welcome__title {
  @include adaptiveDesktopToMobile('font-size', 260, 85);
  font-weight: 700;
  line-height: 100%;
  margin: 0;
}
.welcome__info {
  @include vp-1279 {
    order: 1;
    display: flex;
    gap: 10px;
  }
}
.welcome__info-card {
  &:not(:last-child) {
    @include adaptiveDesktopToMobile('margin-bottom', 40, 0);
  }

  @include vp-1279 {
    padding-top: 8px;
    border-top: 1px solid #FFFFFF;
    flex-grow: 1;
  }
}
.welcome__info-card-title {
  
}
.welcome__info-card-description {
}
.welcome__description {
  align-self: flex-end;
  max-width: 670px;
  margin: 0;
}