.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: var(--gr-orange, linear-gradient(130deg, #FEC356 9.25%, #EC641E 59.97%));
  box-shadow: -6px -4px 15px 0px rgba(255, 255, 255, 0.45) inset;
  padding: 20px 30px;
  transition: $trans-default;
  border: 1px solid transparent;
  font-size: 18px;
  font-weight: 700;
  height: fit-content;

  @include hover-focus {
    border-radius: 30px;
    border: 1px solid var(--gr-orange, #FEC356);
    background: rgba(10, 23, 41, 0.10);
    box-shadow: unset;
  }
}