.why {
}
.why__title {
  text-align: center;
  margin-bottom: 30px;
}
.why__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include vp-1023 {
    grid-template-columns: auto;
  }
}
.why__card {
  border-radius: 40px;
  border: 2px solid var(--border-gradient, #0A1729);
  background: rgba(6, 11, 29, 0.10);
  backdrop-filter: blur(16.443363189697266px);
  @include adaptiveDesktopToMobile('padding-top', 50, 30);
  @include adaptiveDesktopToMobile('padding-bottom', 50, 30);
  @include adaptiveDesktopToMobile('padding-right', 35, 30);
  @include adaptiveDesktopToMobile('padding-left', 35, 30);
  position: relative;

  @include hover-focus {
    .why__card-img-hover {
      opacity: 1;
    }

    .why__card-img-wrapper {
      &::before {
        opacity: 1;
      }
    }
  }
}
.why__card-img-wrapper {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    backdrop-filter: blur(16.443363189697266px);
    z-index: 2;
    opacity: 0;
  }
}
.why__card-img {
  transition: $trans-default;
  z-index: 1;
  margin: 0 auto;
}
.why__card-img-hover {
  position: absolute;
  @include adaptiveDesktopToMobile('top', 50, 30);
  opacity: 0;
  transition: $trans-default;
  z-index: 3;
  @include adaptiveDesktopToMobile('--padding-left', 35, 30);
  width: calc(100% - 2 * var(--padding-left));
}
.why__card-description {
  text-align: center;
  margin: 0 auto;
  @include adaptiveDesktopToMobile('margin-top', 30, 10);
  max-width: 300px;
}