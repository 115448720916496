.benefits {
  @include adaptiveDesktopToMobile('padding-top', 200, 120);
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
}
.benefits__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @include adaptiveDesktopToMobile('column-gap', 20, 0);
  @include adaptiveDesktopToMobile('row-gap', 30, 10);

  @include vp-1279 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-767 {
    grid-template-columns: auto;
  }
}
.benefits__title {
  grid-column: span 2;

  @include vp-1279 {
    margin-bottom: 20px;
  }

  @include vp-767 {
    grid-column: auto;
  }
}
.benefits__card {
  @include adaptiveDesktopToMobile('padding', 40, 20);
  padding-top: unset!important;
  @include adaptiveDesktopToMobile('border-radius', 40, 30);
  border: 2px solid var(--border-gradient, #0A1729);
  background: rgba(6, 11, 29, 0.10);
  backdrop-filter: blur(16.646116256713867px);

  @include vp-767 {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    gap: 30px;
  }
}
.benefits__number {
  text-align: right;
  @include adaptiveDesktopToMobile('font-size', 155, 100);
  font-weight: 700;
  margin-bottom: 30px;
}
.benefits__card-description {
}
.benefits__fake {
  @include vp-1279 {
    display: none;
  }
}