.heart {
  @include adaptiveDesktopToMobile('padding-top', 200, 120);
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
}
.heart__title {
  margin-bottom: 30px;
}
.heart__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include vp-1279 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.heart__card {
  border-radius: 36.415px;
  border: 2px solid var(--border-gradient, #0A1729);
  background: rgba(6, 11, 29, 0.10);
  backdrop-filter: blur(16.386585235595703px);
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:last-child {
    padding-top: 35px;
    background: var(--White, #FFF);
    color: #010103;
    justify-content: flex-start;
  }
}
.heart__card-photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 36.415px;
}
.heart__card-description {
  text-align: center;
  margin-top: 12px;
}
.heart__card-title {
  @include adaptiveDesktopToMobile('margin-bottom', 35, 20);
}
.heart__card-list {
  margin-top: unset;
  padding-left: 20px;
  max-width: 350px;

  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}