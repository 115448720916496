.calculation {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
}
.calculation__title {
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 200, 90);
  font-weight: 500;
  line-height: 60%;
  opacity: 0.4;
  mix-blend-mode: soft-light;
  filter: blur(7px);
  margin: 0;
  @include adaptiveDesktopToMobile('margin-bottom', 70, 30);

  @include vp-767 {
    filter: blur(2.9359772205352783px);
  }
}
.calculation__table {
}
.calculation__table-head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include adaptiveDesktopToMobile('gap', 40, 10);
  @include adaptiveDesktopToMobile('padding-top', 20, 10);
  @include adaptiveDesktopToMobile('padding-bottom', 20, 10);
  @include adaptiveDesktopToMobile('padding-left', 40, 10);
  @include adaptiveDesktopToMobile('padding-right', 40, 10);

  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);

    .calculation__table-cell {
      border-bottom: 1px solid #FFF;
      padding-bottom: 10px;
      
      &:first-child {
        display: none;
      }
    }
  }
}
.calculation__table-cell {
  @include adaptiveDesktopToMobile('font-size', 30, 18);
  font-weight: 700;

  span {
    @include adaptiveDesktopToMobile('font-size', 20, 16);
    opacity: 0.6;
    display: block;
  }

  @include vp-767 {
    text-align: center;
  }
}
.calculation__table-body {
  .calculation__table-row {
    &:last-child {
      border-radius: 20px;
      background: var(--gr-orange, linear-gradient(130deg, #FEC356 9.25%, #EC641E 59.97%));
      color: #0A1729;

      .calculation__table-cell {
        &:not(:first-child) {
          @include adaptiveDesktopToMobile('font-size', 25, 16);
        }
      }
    }
  }
  .calculation__table-cell {
    &:not(:first-child) {
      @include adaptiveDesktopToMobile('font-size', 25, 18);
    }
  }

  @include vp-767 {
    .calculation__table-row {
      margin-top: 40px;
      border-radius: 10px;

      &:nth-child(4) {
        margin-top: 55px;
        
        .calculation__table-cell {
          &:first-child {
            top: -45px;
          }
        }
      }

      &:last-child {
        .calculation__table-cell {
          &:first-child {
            color: #FFF;
          }
        }
      }
    }
    .calculation__table-cell {
      &:first-child {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
    }
  }
}
.calculation__table-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include adaptiveDesktopToMobile('gap', 40, 20);
  @include adaptiveDesktopToMobile('padding-top', 20, 10);
  @include adaptiveDesktopToMobile('padding-bottom', 20, 10);
  @include adaptiveDesktopToMobile('padding-left', 40, 10);
  @include adaptiveDesktopToMobile('padding-right', 40, 10);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(10.498992919921875px);
  @include adaptiveDesktopToMobile('margin-bottom', 20, 15);

  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.calculation__info {
  opacity: 0.5;
}