.header {
  padding: 40px 0;
  z-index: 111;

  &--transition {
    padding: 0;
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%) translateY(200px);
    opacity: 0;

    .container {
      padding: 0;
    }

    .header__wrapper {
      align-items: center;
      padding: 0 15px;
      border-radius: 40px;
      border: 2px solid var(--border-gradient, #0A1729);
      background: rgba(6, 11, 29, 0.10);
      backdrop-filter: blur(16.646116256713867px);

      @include vp-767 {
        padding-left: unset;
      }
    }

    .header__logo {
      display: block;
      margin-right: 13px;

      @include vp-767 {
        display: none;
      }
    }
    .header__burger {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 40px;
      height: 35px;
      position: relative;
      margin-left: 5px;

      &::before {
        content: '';
        display: block;
        width: 19px;
        height: 2px;
        background-color: #FFF;
      }

      &::after {
        content: '';
        display: block;
        width: 19px;
        height: 2px;
        background-color: #FFF;
        margin-top: 6px;
      }
    }
    .header__btn {
      z-index: 1;
    }
    .header__menu {
      position: absolute;
      bottom: 0;
      right: 0;
      transition: $trans-default;
      opacity: 0;
      pointer-events: none;
      width: 290px;

      @include vp-767 {
        width: 100%;
      }
    }
    .header__menu-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 23px 0 85px;
    }
  }

  &--fixed {
    transition: $trans-default;
    opacity: 1;
    transform: translateX(-50%);

    @include vp-767 {
      transition: unset;
    }
  }

  &--active {
    .header__burger {
      &::before {
        transform: rotate(45deg) translateY(1px);
      }
      &::after {
        margin: 0!important;
        transform: rotate(-45deg) translateY(-1px);
      }
    }

    .header__menu {
      opacity: 1!important;
      pointer-events: all!important;
      border-radius: 30px;
      border: 2px solid var(--border-gradient, #0A1729);
      background: rgba(6, 11, 29, 0.70);
      backdrop-filter: blur(16.646116256713867px);
    }
  }

  @include vp-1279 {
    padding: 0;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(200px);
    opacity: 0;

    .container {
      padding: 0;
    }

    .header__wrapper {
      align-items: center;
      padding: 0 15px;
      padding-left: unset;
      border-radius: 40px;
      border: 2px solid var(--border-gradient, #0A1729);
      background: rgba(6, 11, 29, 0.10);
      backdrop-filter: blur(16.646116256713867px);
    }

    .header__logo {
      display: none;
      margin-right: 13px;
    }
    .header__burger {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 40px;
      height: 35px;
      position: relative;
      margin-left: 5px;

      &::before {
        content: '';
        display: block;
        width: 19px;
        height: 2px;
        background-color: #FFF;
      }

      &::after {
        content: '';
        display: block;
        width: 19px;
        height: 2px;
        background-color: #FFF;
        margin-top: 6px;
      }
    }
    .header__btn {
      z-index: 1;
    }
    .header__menu {
      position: absolute;
      bottom: 0;
      right: 0;
      transition: $trans-default;
      opacity: 0;
      pointer-events: none;
      width: 100%;

      .header__logo {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
      }
    }
    .header__menu-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 23px 0 85px;
    }

    transition: unset;
    opacity: 1;
    transform: translateX(-50%);
  }
}
.header__wrapper {
  display: flex;
  justify-content: space-between;
  @include adaptiveDesktopToMobile('padding-left', 500, 0);
}
.header__logo {
  display: none;
}
.header__burger {
  display: none;
}
.header__menu {
  .header__logo {
    display: none;
  }
}
.header__menu-list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include adaptiveDesktopToMobile('column-gap', 60, 0);
  @include adaptiveDesktopToMobile('row-gap', 30, 0);

  @include hover-focus {
    .header__menu-link {
      opacity: 0.4;
    }
  }
}
.header__menu-item {
  
}
.header__menu-link {
  font-size: 18px;
  transition: $trans-default;

  @include hover-focus {
    opacity: 1!important;
  }
}
.header__btn {
  white-space: nowrap;
}