.footer {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 120);
}
.footer__title {
  margin-bottom: 35px;
}
.footer__contacts {
  display: flex;
  @include adaptiveDesktopToMobile('gap', 35, 15);

  @include vp-767 {
    flex-direction: column
  }
}
.footer__contact {
  @include adaptiveDesktopToMobile('font-size', 30, 18);
  font-weight: 700;
}
.footer__contact-title {
  margin-bottom: 10px;
}
.footer__contact-link {
  color: #EC641E;
}